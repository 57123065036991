.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rating-card:hover {
  box-shadow: 0px 40px 20px 5px #e4e4e4;
}

.send-mail-card {
  box-shadow: 0px 40px 20px 5px #e4e4e4;
}
